import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation hook

const AdComponent = ({ adId, dimension, className }) => {
  const location = useLocation(); // Get the current location

  useEffect(() => {
    console.log("Current URL Pathname:::::", location.pathname);

    const script = document.createElement("script");
    script.src = "https://appsha-prm.ctengine.io/js/script.js?wkey=qIQoYxUHd9"; // test
    script.src ="https://appsha-prm.ctengine.io/js/script.js?wkey=RjEgd8MYG2"; // live
    // <script async src="https://appsha-prm.ctengine.io/js/script.js?wkey=RjEgd8MYG2"></script>
    script.async = true;

    script.onload = () => {
      if (window.CoinTraffic) {
        window.CoinTraffic.reloadAds(); // Reload ads when URL changes
      }
    };

    document.body.appendChild(script);

    return () => {
      // Cleanup to remove the script when component unmounts
      document.body.removeChild(script);
    };
  }, [location.pathname]); // Trigger effect when pathname changes

  return (
    <div className="header-footer-ad">
      <div className={`${className || ""}`}>
        <span id={adId} className="contraffic_ad"></span>
      </div>
    </div>
  );
};

export default AdComponent;
