import { Button, Col, Container, Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { useState, useEffect } from "react";
import moment from "moment";
import { apiService } from "../service/api.service";
import { formatAddress, formatBalance } from "../common/connectWallet";
import { adClient } from '../common/adConfig'
import Pagination from "react-js-pagination";
// import swal from "sweetalert";
import { adConfig, consttransactionurl } from "../config/config"
import { ThreeDotSpinner } from "./loader/index"
import Select from "react-select";
import AdComponent from "../common/AdComponent";
const TransactionManagement = () => {
    const PointsProgram = [
        { value: "All", label: "All time" },
        { value: "Top Earners", label: "Past 30 days" },
    ]

    const [loader, setLoader] = useState(false)
    const [ranks, setRanks] = useState()
    const [totalCount, settotalCount] = useState()
    const [page, setpage] = useState(0)
    const [leaderBoardStatus, setLeaderBoardStatus] = useState("All")

    useEffect(() => {
        userTransaction(leaderBoardStatus, page);
    }, [leaderBoardStatus])


    async function changeLeaderBoardStatus(newStatus) {
        setpage(0)
        setLeaderBoardStatus(newStatus)
    }


    async function userTransaction(leaderBoardStatusTmp, page) {

        setLoader(true)
        try {
            let response;
            if (leaderBoardStatusTmp == "All") {
                response = await apiService.leaderBoardPoints(page);
            } else {
                response = await apiService.leaderBoardPoints30Days(page);
            }

            if (response.status == 200) {
                console.log("response.data.data>>>>>::::::::::", leaderBoardStatusTmp, response.data.data);
                setRanks(response.data.data)
                settotalCount(response.data.data.totalRecords)
            }
            setLoader(false)
        } catch (error) {

            setLoader(false);
            if (error?.response?.status == 401) {
                // swal({ text: "Unauthorized", button: "OK" }).then(() => {
                localStorage.clear();
                window.location.href = "/";
                // });
            } else {
            }
        }
    }

    function handlePageChange(pageNumber) {
        if (page != pageNumber - 1) {
            setpage(pageNumber - 1);
            userTransaction(leaderBoardStatus, pageNumber - 1)
        }

    }

    function get_rank_td(rank) {

        if (rank == 1) {
            return <td><img src={require("../assets/images/number-one.png")} alt="img" /></td>
        }
        else if (rank == 2) {
            return <td><img src={require("../assets/images/number-two.png")} alt="img" /></td>
        }
        else if (rank == 3) {
            return <td><img src={require("../assets/images/number-three.png")} alt="img" /></td>
        } else {
            return <td>{rank}</td>
        }

    }

    function get_class_name(rank) {
        if (rank == 1) {
            return "first-winner"
        }
        else if (rank == 2) {
            return "second-winner"
        }
        else if (rank == 3) {
            return "third-winner"
        } else {
            return "common-section"
        }
    }

    console.log("ranks", ranks)

    function tooltip(text) {
        return (
            <Tooltip id="tooltip">
                {text}
            </Tooltip>
        )
    }

    // useEffect(() => {
    //     adClient.showBannerAd({
    //         adUnitId: "271833e4-a718-4d71-b73e-955e35b1b33d",  // live
    //         // adUnitId: "23a5da96-5f02-4884-b4f9-8ca6a5bc95d7",  // test
    //         containerId: "ads-banner-top"
    //     }, (errorMessage) => {
    //         console.log("errorMessage", errorMessage);
    //         // You can handle error here.
    //     })
    // }, []);

    // useEffect(() => {
    //     adClient.showBannerAd({
    //         adUnitId: "7ef30ab6-59e1-45b9-9072-591153082a1e", //live
    //         // adUnitId: "591b109d-963c-4173-804b-817eeeaa696a", // test
    //         containerId: "ads-home-right"
    //     }, (errorMessage) => {
    //         console.log("errorMessage", errorMessage);
    //         // You can handle error here.
    //     })
    // }, []);

    // useEffect(() => {
    //     adClient.showBannerAd({
    //         adUnitId: "bcfbb4d5-0c6c-49e4-804f-2eb1a21e61f0", //live
    //         // adUnitId: "dede3c24-01ed-4c28-870f-cc90a888a9c9", // test
    //         containerId: "ads-home-left"
    //     }, (errorMessage) => {
    //         console.log("errorMessage", errorMessage);
    //         // You can handle error here.
    //     })
    // }, []);
    const adData = adConfig["LeaderBoard"]; // Get specific page ad config


    return (
        <>
        {window.innerWidth <= 768 ? (
      <AdComponent adId={adData.ids[3]} dimension={adData.dimensions[3]} className={"ads-banner-top"} />):
      (
        <AdComponent adId={adData.ids[0]} dimension={adData.dimensions[0]} className={"ads-banner-top"} />)}
            {/* <AdComponent adId={adData.ids[0]} dimension={adData.dimensions[0]} className={"ads-banner-top"} /> */}

<AdComponent adId={adData.ids[1]} dimension={adData.dimensions[1]} className={"ads-home-right"} />

<AdComponent adId={adData.ids[2]} dimension={adData.dimensions[2]} className={"ads-home-left"} />

            <section className="transaction-management-area">
                {loader && <> <ThreeDotSpinner /></>}
                <Container>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="justify-content-center">
                            <Col md={12} lg={10}>
                                <div className="leaderboard-info-top">
                                    <h6>Points Program Rules:</h6>
                                    <p>Placing a prediction = 5 points   |    Winning a prediction = 10 points </p>
                                    {/* <ul>
                                        <li>Placing a prediction with minimum 100 USDC = 10 points </li>
                                        <li>Winning a prediction with minimum 100 USDC = 20 points </li>
                                        <li> Placing a prediction with minimum 1,000 USDC= 30 points</li>
                                        <li>Winning a prediction with minimum 1,000 USDC = 60 points  </li>
                                    </ul> */}
                                </div>
                                <div className="transaction-top-heading leader-board-info-bottom">
                                    <div className="top-leaderboard-left">
                                        <img src={require("../assets/images/winners.png")} alt="img" />
                                    </div>
                                    <div className="top-leaderboard-right">
                                        <h2>Winner’s Wall <img src={require("../assets/images/medal.png")} alt="img" /></h2>
                                        <p>Celebrating the Top Points Earners on BetFolio</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className=" justify-content-center">
                            <Col md={12} lg={10}>

                                <div className="audit-request-box leaderboard-inner">
                                    <div className="board-filters">
                                        <Form.Group
                                            className="mb-2"
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label>Status:</Form.Label>
                                            <Select options={PointsProgram} placeholder="All time"
                                                onChange={(e) => changeLeaderBoardStatus(e.value)}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        background: "#1d0042",
                                                        border: "1px solid rgb(255 255 255 / 50%)",
                                                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
                                                        borderRadius: "10px",
                                                        "&:focus": {
                                                            borderColor: "rgb(255 255 255 / 50%)",
                                                        },
                                                        "&:hover": {
                                                            borderColor: "rgb(255 255 255 / 50%)",
                                                        },
                                                    }),
                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Rank</th>
                                                <th className="leaderboard-address">Wallet Address</th>
                                                <th className="leaderboard-address">Total Predictions</th>
                                                <th className="leaderboard-address">
                                                    {leaderBoardStatus === "Top Earners" ? "30 Day Points" : "All Time Points"}

                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {leaderBoardStatus === "All"
                                                                    ? "Your total all time points."
                                                                    : "Your total 30 day points."}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Button bsStyle="default">
                                                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </th>

                                                <th className="leaderboard-address">
                                                    Reward Points
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {leaderBoardStatus === "All"
                                                                    ? "Your total all time points minus any points converted to FOLIO."
                                                                    : "Your total 30 day points minus any points converted to FOLIO."}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Button bsStyle="default">
                                                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {ranks?.userPointsDetail?.map((data, index) => {
                                                const rankData = get_rank_td(data.rank);
                                                const className = get_class_name(data.rank);
                                                const formattedWallet = formatAddress(data.user_wallet_address);
                                                const rewardPoints = Math.max(data?.points - (data?.redemmed_points || 0), 0
                                                );

                                                return (
                                                    <tr className={className} key={index}>
                                                        {rankData}
                                                        <td>{formattedWallet}</td>
                                                        <td>{data.predictionCount ? data.predictionCount : 0}</td>
                                                        <td>{data.points}</td>
                                                        <td>{rewardPoints}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>

                                    {totalCount && totalCount > 50 ?

                                        <Pagination
                                            activePage={page + 1}
                                            itemsCountPerPage={50}
                                            totalItemsCount={totalCount}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

        </>
    );
};
export default TransactionManagement;