import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  formatBalanceWithDecimals
} from "../common/connectWallet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

function capitalizeFirstLetter(str) {
    str = str.toString();
    return str.charAt(0).toUpperCase() + str.slice(1);
  } 

function PredictionOptions({ data }) {
  const { prediction_category, prediction_type, countsAndAmounts, answer_option, decimal } = data;

  if (prediction_category !== "REGULAR") {    
    if (answer_option?.length > 2) {
      return (
        <div className="option-swiper">
          <Swiper spaceBetween={10} slidesPerView={2} navigation={true} modules={[Navigation]} onSwiper={(swiper) => console.log(swiper)}>
            {answer_option.map((option, index) => (
              <SwiperSlide key={index}>
                <OptionItem option={option} count={countsAndAmounts[`count_${index}`]} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      );
    }
    return <BasicList data={data} />;
  }

  if (prediction_type === "RISK-FREE") {
    return (
      <ul>
        <li>
          <i className="fa fa-check" aria-hidden="true"></i> True
          <p> {countsAndAmounts.count_0} bets</p>
        </li>
        <li>
          <i className="fa fa-times" aria-hidden="true"></i> False
          <p> {countsAndAmounts.count_1} bets</p>
        </li>
      </ul>
    );
  }

  return (
    <ul>
      {answer_option.map((option, index) => (
        <li key={index}>
          {option && <i className={`fa fa-${option ? 'check' : 'times'}`} aria-hidden="true"></i>}
          {capitalizeFirstLetter(option)}
          <p>
            <span>
              {formatBalanceWithDecimals(countsAndAmounts[`amount_${index}`], decimal)} USDC
            </span>{" "}
            ({countsAndAmounts[`count_${index}`]} bets)
          </p>
        </li>
      ))}
    </ul>
  );
}

function BasicList({ data }) {
  const { prediction_type, answer_option, countsAndAmounts } = data;

  return (
    <ul>
      {answer_option?.map((option, index) => (
        <li key={index}>
          {option}
          <p>
            <span>
              {prediction_type === "RISK-FREE" ? `${countsAndAmounts[`count_${index}`]} ${countsAndAmounts[`count_${index}`] == 1 ? "bet" : "bets"}` : `${formatBalanceWithDecimals(countsAndAmounts[`amount_${index}`], data.decimal)} USDC (${countsAndAmounts[`count_${index}`]} ${countsAndAmounts[`count_${index}`] == 1 ? "bet" : "bets"})`}
            </span>
          </p>
        </li>
      ))}
    </ul>
  );
}

function OptionItem({ option, count }) {
  return (
    <li className="listing-option-area">
      <div className="listing-option-area-inner">
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{option}</Tooltip>}>
          <h6>{option}</h6>
        </OverlayTrigger>
        <p>{count} {count == 1 ? "bet" : "bets"}</p>
      </div>
    </li>
  );
}

export default PredictionOptions;
