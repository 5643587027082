export const validateService = {
   contactusValidate


}
 const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

function contactusValidate(contactFields,setcontactFieldsErr){
    
                  let formValidated = true;
                  if (contactFields.name.trim() === "") {
                      formValidated = false;
                      setcontactFieldsErr((obj) => {
                          return {
                          ...obj,
                          name: "Please enter your name",
                          email: "",
                          phone: "",
                          message: "",
                          
                          };
                      });
              } else if (contactFields.email.trim() ==""){
                  
                  formValidated = false;
                  setcontactFieldsErr((obj) => {
                      return {
                      ...obj,
                      name: "",
                      email: "Please enter email",
                      phone: "",
                      message: "",
                      
                      };
                  });
              }else if(!EMAILREGEX.test(contactFields.email)){
                formValidated = false;
                  setcontactFieldsErr((obj) => {
                      return {
                      ...obj,
                      name: "",
                      email: "Please enter valid email",
                      phone: "",
                      message: "",
                      
                      };
                  });
              }
              
              else if (contactFields.phone_number === "") {
                formValidated = false;
                setcontactFieldsErr((obj) => {
                  return {
                    ...obj,
                    name: "",
                      email: "",
                      phone: "Please enter phone number",
                      message: "",
                  };
                });
                
              }else if (contactFields.message.trim()=== "") {
                formValidated = false;
                setcontactFieldsErr((obj) => {
                  return {
                    ...obj,
                    name: "",
                    email: "",
                    phone: "",
                    message: "Please enter message",
                  };
                });
              }
              else{
                  formValidated = true;
                  setcontactFieldsErr((obj) => {
                    return {
                      ...obj,
                      name: "",
                    email: "",
                    phone: "",
                    message: "",
                    };
                  });
                }
              return formValidated
 }