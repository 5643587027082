import { Button, Col, Container, Form, OverlayTrigger, ProgressBar, Row, Tooltip } from "react-bootstrap";

const RiskFreePrediction = () => {
    const tooltip = (
        <Tooltip id="tooltip">
            This payout is subject to change and calculated by considering the bets made by other participants and the current total value locked (TVL) so far.
        </Tooltip>
    );
    return (
        <>
            <section className="common-padding prediction-detail-portion">
                <Container className="spacing-area-section">
                    <div className="detail-top-area">
                        <div className="back-top-btn">
                            <Button type="button" variant="unset"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</Button>
                        </div>
                        <div className="back-top-btn">
                            <a href="#" target="_blank">View Result  </a>
                        </div>
                    </div>
                    <Row>
                        <Col md={12} lg={7} xl={7}>
                            <div className="prediction-detail-left">
                                <div className="prediction-detail-image">
                                    <img src={require("../assets/images/dummy.png")} alt="img" />
                                </div>
                                <div className="prediction-detail-left-content">
                                    <h5>Uniswap TVL  $6 billion by March 20th</h5>
                                    <p>By end of day on March 20th, the total value locked (TVL) of Uniswap will pass $6 billion.</p>
                                    <div className="left-spacing-area">
                                        <h6>Make Your Prediction:</h6>
                                        <Form>

                                            <Form.Group >
                                                <Form.Label>Prediction:</Form.Label>
                                                {['radio'].map((type) => (
                                                    <div key={`default-${type}`} className="mb-4 radio-btn-option">
                                                        <Form.Check
                                                            type={type}
                                                            id={`default-${type}`}
                                                            label={`True`}
                                                            name="outcome"
                                                        />
                                                        <Form.Check
                                                            type={type}
                                                            id={`default-${type}`}
                                                            label={`False`}
                                                            name="outcome"
                                                        />
                                                    </div>
                                                ))}
                                            </Form.Group>
                                        </Form>
                                        <Button type="button" variant="unset" >Place Bet</Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} lg={5} xl={5}>
                            <div className="prediction-detail-right">



                                <p className="correct-answer">Result: <span>false</span>  </p>

                                <div className="prediction-time-top-area">
                                    <img src={require("../assets/images/bg-vector-days.png")} />
                                    <p>1 day </p>
                                </div>
                                <div className="prediction-detail-right-top-heading">
                                    <h6>Outcomes</h6>
                                    <div className="betting-area">

                                        <p>
                                            <span className="betting-text">Betting Window:</span>Closed

                                            {/* {betEndTime ?
                                                <Countdown
                                                    date={moment(betEndTime).format('YYYY-MM-DD HH:mm:ss')}
                                                    renderer={renderer}
                                                >  </Countdown>
                                                :
                                                null
                                            } */}

                                        </p>

                                    </div>

                                </div>
                                <ProgressBar />
                                <div className="progress-bar-info">
                                    <div className="progess-bar-info-left">
                                        <h6>True</h6>
                                    </div>
                                    <div className="progess-bar-info-right">
                                        <h6>False</h6>
                                    </div>
                                </div>
                                {/* <p className="progress-total"> Total : <span><b></b> USDC </span> bets</p> */}
                                <div className="betting-window">
                                    <h6>Prediction Timeframe  :</h6>
                                    <div className="betting-window-content">
                                        <div className="start-end-date">
                                            <div className="start-date">
                                                <p>Start Date & Time </p>
                                            </div>
                                            <div className="end-date">
                                                <p>End Date & Time</p>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="start-end-date">
                                            <div className="start-date-info">
                                                <p>
                                                    Mar 20, 05:30 AM
                                                </p>
                                                <p>
                                                    Mar 20, 00:00 AM <span>(UTC)</span>
                                                </p>

                                            </div>
                                            <div className="end-date-info">
                                                <p>Mar 21, 05:30 AM</p>
                                                <p>Mar 21, 00:00 AM
                                                    <span>(UTC)</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="leader-board">
                                    <div className="leader-board-top">
                                        <h6>Prediction Leader-board </h6>

                                        <p style={{ cursor: "pointer" }}>View More</p>
                                    </div>
                                    <div className="leader-board-content-area">
                                        <div className="leader-board-content">
                                            <h5>0x5160......8854b1</h5>
                                            <p><span>true</span></p>
                                        </div>
                                        <div className="leader-board-content">
                                            <h5>0x5160......8854b1</h5>
                                            <p><span>true</span></p>
                                        </div>
                                        <div className="leader-board-content">
                                            <h5>0x5160......8854b1</h5>
                                            <p><span>true</span></p>
                                        </div>
                                    



                                        {/* <div className="no-data-area">
                                            <img src={require("../assets/images/no-data.gif")} alt="img" />
                                            <p> No Data Found</p>
                                        </div> */}

                                    </div>

                                </div>
                            </div>
                        </Col>

                        <span id="ct_c9Foar5zBcg" className="contraffic_home_add"></span>
                        <span id="ct_c43z8P1Kuho"></span>
                    </Row>
                </Container>
            </section>
        </>
    );
};
export default RiskFreePrediction;