import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// Import images
import pearlsImg from '../assets/images/pearls.png';
import sapphiresImg from '../assets/images/sapphires.png';
import rubiesImg from '../assets/images/rubies.png';
import emeraldsImg from '../assets/images/emeralds.png';
import diamondsImg from '../assets/images/diamonds.png';
import pebblesImg from '../assets/images/pebble.png';



const prizePools = {
  pearls: "Pearl: $",
  sapphires: "Sapphire: $",
  rubies: "Ruby: $",
  emeralds: "Emerald: $",
  diamonds: "Diamond: $",
  pebbles:"Pebbles: $"
};

// Map the images to their respective types
const images = {
    pearls: pearlsImg,
    sapphires: sapphiresImg,
    rubies: rubiesImg,
    emeralds: emeraldsImg,
    diamonds: diamondsImg,
    pebbles:pebblesImg
};

const SoloTypeTooltip = ({ soloType ,amount , withoutTooltip }) => {
  
  if (!soloType) return null;

  const lowerCaseSoloType = soloType.toLowerCase();
  const renderTooltip = (props) => (
    <Tooltip id="tooltip" {...props}>
      { ` ${prizePools[lowerCaseSoloType]}${amount} Prize Pool`}
    </Tooltip>
  );

  return (
    withoutTooltip ? (
      <img 
        src={images[lowerCaseSoloType]} 
        alt={soloType} 
      />
    ) : (
      <div className="poll-prizes-ara">
        <OverlayTrigger placement="top" overlay={renderTooltip}>
          <img 
            src={images[lowerCaseSoloType]} 
            alt={soloType} 
          />
        </OverlayTrigger>
      </div>
    )
  );
};

SoloTypeTooltip.propTypes = {
  soloType: PropTypes.string,
};

export default SoloTypeTooltip;
