import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    walletAddress: { accounts: [] } ,
    walletConnected: false ,
    walletBalance: undefined ,
    web3AuthProvider: null, // Add global web3Auth provider state

}

export const indexSlice = createSlice({
  name: 'indexslice',
  initialState,
  reducers: {
    setWalletAddress: (state, action) => {
        state.walletAddress = action.payload
    },
    setwalletConnected: (state, action) => {
        state.walletConnected = action.payload
    },
    setwalletBalance: (state, action) => {
      state.walletBalance = action.payload
  },
  setWeb3AuthProvider: (state, action) => {
    state.web3AuthProvider = action.payload; // Set global provider
  },
  },
})

// Action creators are generated for each case reducer function
export const { setWalletAddress,setwalletConnected,setwalletBalance,setWeb3AuthProvider} = indexSlice.actions

export default indexSlice.reducer