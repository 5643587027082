import React from 'react';
import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a'],
  allowedAttributes: {
    'a': ['href','target']
  },
  allowedIframeHostnames: ['www.youtube.com'],
  transformTags: {
    'a': sanitizeHtml.simpleTransform('a', {rel: 'noopener noreferrer'}, true)
  }
};

const sanitize = (dirty, options = {}) => {
  try {
    return { __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }) };
  } catch (error) {
    console.error('Error sanitizing HTML:', error);
    return { __html: '' };
  }
};

const SanitizeHTML = React.memo(({ html, options }) => (
  <div dangerouslySetInnerHTML={sanitize(html, options)} />
));

SanitizeHTML.defaultProps = {
  options: {}
};

export default SanitizeHTML;
