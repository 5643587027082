import { Button, Col, Container, Row } from "react-bootstrap";
import { adClient } from '../common/adConfig'
import { useEffect, useRef, useState } from "react";
import { adConfig } from "../config/config";
import AdComponent from "./AdComponent";

const Footer = ({page}) => {
  const adData = adConfig["Footer"]; // Get specific page ad config

  return <>
    <div className="footer-area">
    {window.innerWidth <= 768 ? (
      <AdComponent adId={adData.ids[1]} dimension={adData.dimensions[1]} className={"ads-footer"} />):
      (
        <AdComponent adId={adData.ids[0]} dimension={adData.dimensions[0]} className={"ads-footer"} />)}

      <section className="footer">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="footer-left-area">
                <ul>
                  <li><a href="/terms-services"> Terms or Service</a></li>
                  <li><a href="/privacy-policy">Privacy Policy</a> </li>
                </ul>
              </div>
            </Col>
            <Col md={6}>
              <div className="footer-content">
                <div className="social-icons">
                  <h6>Social Media Links </h6>
                  <ul>
                    <li><a href="https://warpcast.com/betfolio" target="blank"><img src={require("../assets/images/warpcast.png")} alt="img" /></a></li>
                    <li><a href="https://t.me/betfoliox" target="blank"><i class="fa fa-telegram" aria-hidden="true"></i></a></li>
                    <li><a href="https://twitter.com/BetFolioX" target="blank"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                  </ul>
                </div>
              </div>

            </Col>
          </Row>
        </Container>

      </section>
      <section className="bottom-footer">
        <Container>
          <p>All rights Reserved© betfolio.co, 2024</p>
        </Container>
      </section>
      {/* <img src={require("../assets/images/bottom-vector.png")} alt="img" /> */}
    </div>

  </>;
};
export default Footer;
