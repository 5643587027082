import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { apiService } from "../service/api.service";

const SubscribeForm = ({contractAddress,prediction}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const validateEmail = (email) => {
    const emailRegex =
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    // Email validation
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    // API request
    try {
      const response = await apiService.subscribePrediction({user_email:email, contract_address:contractAddress});

      if (response.status === 200) {
        setSuccess(response.data.message);
        setEmail(""); // Clear input after success

        setTimeout(()=>{
            setSuccess();
        },5000)
      }
    } catch (error) {
      setError(error?.response?.data?.message ? error.response.data.message :"Failed to subscribe. Please try again.");
    }
  };

  return (
    <Row className="justify-content-center">
      <Col md={12} >
        <div className="subscribe-area">
          <img src={require("../assets/images/Email-Phishing-Hook.png")} alt="Email Phishing Hook" />
          <h4>Subscribe</h4>
          <p>Subscribe to receive results updates for this prediction. Earn 5 points the first time you subscribe.</p>
          <Form onSubmit={handleSubmit}>
            <Form.Label>Email</Form.Label>
            <div className="subscribe-email-area">
              <Form.Control
                type="text"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mr-sm-2"
                isInvalid={!!error}
              />
              <Button type="submit" disabled={prediction?.result}>Submit</Button>
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {success && <p style={{ color: "green" }}>{success}</p>}
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default SubscribeForm;
