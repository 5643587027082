import { PersonaAdSDK } from '@personaxyz/ad-sdk';
import { env } from 'process';

const personaConfig = {
    apiKey: 'persona-pub-0xfb484f1fe1a61276d8af32d8d8e3a7b1', // live
    environment: 'production', // use value 'production' when going live
    // apiKey:"persona-pub-0x2d85fd210efa70081b78e224b4467b6d", // test
    // environment: 'sandbox',

}

const sdk = new PersonaAdSDK(personaConfig)

export const adClient = sdk.getClient()
