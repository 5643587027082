import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { apiService } from "../service/api.service";
import { validateService } from "../service/validation.service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import swal from "sweetalert";
import { ThreeDotSpinner } from "./loader/index"
import { adClient } from '../common/adConfig'
import { adConfig } from "../config/config";
import AdComponent from "../common/AdComponent";

const ContactForm = () => {

    // useEffect(() => {
    //     adClient.showBannerAd({
    //         adUnitId: "271833e4-a718-4d71-b73e-955e35b1b33d",  // live
    //         // adUnitId: "23a5da96-5f02-4884-b4f9-8ca6a5bc95d7",  // test
    //         containerId: "ads-banner-top"
    //     }, (errorMessage) => {
    //         console.log("errorMessage", errorMessage);
    //         // You can handle error here.
    //     })
    // }, []);

    // useEffect(() => {
    //     adClient.showBannerAd({
    //         adUnitId: "7ef30ab6-59e1-45b9-9072-591153082a1e", //live
    //         // adUnitId: "591b109d-963c-4173-804b-817eeeaa696a", // test
    //         containerId: "ads-home-right"
    //     }, (errorMessage) => {
    //         console.log("errorMessage", errorMessage);
    //         // You can handle error here.
    //     })
    // }, []);

    // useEffect(() => {
    //     adClient.showBannerAd({
    //         adUnitId: "bcfbb4d5-0c6c-49e4-804f-2eb1a21e61f0", //live
    //         // adUnitId: "dede3c24-01ed-4c28-870f-cc90a888a9c9", // test
    //         containerId: "ads-home-left"
    //     }, (errorMessage) => {
    //         console.log("errorMessage", errorMessage);
    //         // You can handle error here.
    //     })
    // }, []);

    const [loader, setLoader] = useState(false)
    const COUNTRY_CODE = "US"
    const [contactFields, setcontactFields] = useState({
        name: "",
        email: "",
        phone_number: "",
        message: "",
    });
    const [contactFieldsErr, setcontactFieldsErr] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });


    const submitForm = async () => {
        let valid = validateService.contactusValidate(contactFields, setcontactFieldsErr)

        if (valid) {
            try {
                setLoader(true)
                const response = await apiService.contactUs(contactFields);
                if (response.status == 200) {
                    setcontactFields({
                        name: "",
                        email: "",
                        phone_number: "",
                        message: "",
                    })
                    swal("Success", response.data.message, "success")
                    console.log(response.data)
                    setLoader(false)

                }
            } catch (error) {
                setLoader(false);
                if (error?.response?.status == 401) {
                    // swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    // });
                } else {
                }
            }
        }

    }
    const adData = adConfig["ContactUs"]; // Get specific page ad config

    return (
        <>
           {window.innerWidth <= 768 ? (
      <AdComponent adId={adData.ids[3]} dimension={adData.dimensions[3]} className={"ads-banner-top"} />):
      (
        <AdComponent adId={adData.ids[0]} dimension={adData.dimensions[0]} className={"ads-banner-top"} />)}

<AdComponent adId={adData.ids[1]} dimension={adData.dimensions[1]} className={"ads-home-right"} />

<AdComponent adId={adData.ids[2]} dimension={adData.dimensions[2]} className={"ads-home-left"} />
            <section className="contact-form">
                {loader && <> <ThreeDotSpinner /></>}
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10} lg={7} xl={6}>
                            <div className="inner-contact-form">
                                <h2>Contact Us</h2>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control maxLength={50} type="text" placeholder="Enter name" value={contactFields.name}
                                            onChange={(e) => {
                                                setcontactFields((prevObj) => {
                                                    return {
                                                        ...prevObj,
                                                        name: e.target.value,
                                                    };
                                                });
                                            }}
                                        />
                                        <span className="error">{contactFieldsErr.name}</span>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email"
                                            value={contactFields.email}
                                            onChange={(e) => {
                                                setcontactFields((prevObj) => {
                                                    return {
                                                        ...prevObj,
                                                        email: e.target.value,
                                                    };
                                                });
                                            }}

                                        />
                                        <span className="error">{contactFieldsErr.email}</span>
                                    </Form.Group>
                                    <Form.Group className="mb-3 phone-area" controlId="formBasicPassword">
                                        <Form.Label>Phone Number</Form.Label>
                                        <PhoneInput

                                            country={
                                                COUNTRY_CODE ? COUNTRY_CODE.toLocaleLowerCase() : "us"
                                            }
                                            className={

                                                "phone-input "
                                            }
                                            placeholder="Enter contact number"
                                            value={contactFields.phone_number}
                                            onChange={(e) => {
                                                setcontactFields((prevObj) => {
                                                    return {
                                                        ...prevObj,
                                                        phone_number: e,
                                                    };
                                                });


                                            }}

                                        />
                                        {/* <Form.Control type="text" placeholder="Enter phone number" 
                                         onChange={(e) => {
                                            setcontactFields((prevObj) => {
                                                return {
                                                ...prevObj,
                                                phone: e.target.value,
                                                };
                                            });
                                            }}
                                        /> */}
                                        <span className="error">{contactFieldsErr.phone}</span>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control maxLength={1000} as="textarea" rows={3} placeholder="Enter Message"
                                            onChange={(e) => {
                                                setcontactFields((prevObj) => {
                                                    return {
                                                        ...prevObj,
                                                        message: e.target.value,
                                                    };
                                                });
                                            }}
                                            value={contactFields.message}
                                        />
                                        <span className="error">{contactFieldsErr.message}</span>
                                    </Form.Group>
                                    <Button variant="primary" type="button" onClick={submitForm}>
                                        Submit
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};
export default ContactForm;