import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ConnectWalletPopup = (props) => {


  

    return (
        <>
          <Modal show={props.show} onHide={props.handleClose} centered className="connect-wallet-box">
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <img src={require("../assets/images/wallet-not-connected.png")} alt="wallet" /> */}
                    <img src={require("../assets/images/wallet-connected.png")} alt="wallet" />
                    <h3>Connected</h3>
                    <p>Wallet Connected Successfully</p></Modal.Body>
                <Modal.Footer>
                    <Button variant="unset" onClick={props.handleClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal></>
    );
};
export default ConnectWalletPopup;