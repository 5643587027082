import React from 'react';
import { formatBalanceWithDecimals } from "./connectWallet";
import PropTypes from 'prop-types';

const PredictionAmount = ({ prediction }) => {
  if (!prediction) return null;

  const formattedAmount = prediction.prediction_type === "PAID" 
    ? formatBalanceWithDecimals(prediction?.total_amount, prediction?.decimal) 
    : formatBalanceWithDecimals(prediction.amount, prediction.decimal);

    if (!formattedAmount || isNaN(formattedAmount)) return null;

  return (
    <>
      {formattedAmount} {prediction.symbol}
    </>
  );
};

PredictionAmount.propTypes = {
  prediction: PropTypes.shape({
    prediction_type: PropTypes.string.isRequired,
    total_amount: PropTypes.number,
    amount: PropTypes.number,
    decimal: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
  }).isRequired,
};

export default PredictionAmount;
