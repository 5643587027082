import { Container } from "react-bootstrap";

const TermServices = () => {
    return (
        <>
            <section className="common-padding">
                <Container>
                    <div className="terms-services-area">
                        <h2>Terms of Service Agreement for BetFolio</h2>
                        <h6>1. Introduction</h6>
                        <p>Welcome to BetFolio! By accessing our website and using our services, you agree to be bound by these Terms of Service ("Terms"). BetFolio offers a prediction market platform for crypto portfolios ("Services"). These Terms govern your use of our Services and provide information about the BetFolio Service, outlined below. When you create a BetFolio account or use BetFolio, you agree to these terms.</p>
                        <h6>2. Eligibility</h6>
                        <p><b>To use our Services, you must:</b></p>
                        <p>a. Be at least eighteen (18) years old.</p>
                        <p>b. Have the legal capacity to enter into a binding contract with us.</p>
                        <p>c. Not be prohibited by law from accessing our Services or have any restriction from being involved in betting or prediction markets.</p>
                        <h6>3. Account Registration</h6>
                        <p><b>You must register for an account to use our Services. When registering, you agree to:
                        </b></p>
                        <p>a. Provide accurate, current, and complete information.</p>
                        <p>b. Maintain and promptly update your account information.</p>
                        <p>c. Maintain the security of your password and accept all risks of unauthorized access to your account.
                        </p>
                        <h6>4. Service Usage</h6>
                        <p><b>You agree to:</b></p>
                        <p>a. Use our Services only for lawful purposes and in accordance with these Terms.
                        </p>
                        <p>b. Ensure all activities conducted through your BetFolio account comply with applicable laws, regulations, and guidelines.</p>
                        <p>c. Not engage in any behavior that could damage, disable, overburden, or impair the functioning of BetFolio in any manner.</p>
                        <h6>5. Intellectual Property Rights</h6>
                        <p>
                            The content, information, software, designs, and data included in our Services are the property of BetFolio and its licensors and are protected by intellectual property laws. You may not use, copy, modify, or distribute such content without express permission from the owner.
                        </p>
                        <h6>6. Risk Disclosure</h6>
                        <p>You acknowledge that participating in prediction markets carries financial risk and may result in significant losses. BetFolio is not responsible for any decisions or actions you take or authorize third parties to take on your behalf based on information obtained through our Services.</p>
                        <h6>7. Termination</h6>
                        <p>BetFolio may terminate or suspend your account and bar access to the Services immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever, including, but not limited to, a breach of these Terms.</p>
                        <h6>8. Disclaimer of Warranty</h6>
                        <p>BetFolio provides its Services “as is” and does not offer any warranty, express or implied, regarding the reliability, accuracy, or availability of the Services.</p>
                        <h6>9. Limitation of Liability</h6>
                        <p>In no event shall BetFolio, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Services.</p>
                        <h6>10. Governing Law</h6>
                        <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction where BetFolio is established, without regard to its conflict of law provisions.</p>
                        <h6>11. Changes to Terms</h6>
                        <p>BetFolio reserves the right to modify or replace these Terms at any time. We will provide at least 30 days' notice before any new terms take effect. By continuing to access or use our Services after those revisions become effective, you agree to be bound by the revised terms.</p>
                        <h6>12. Contact Information</h6>
                        <p>If you have any questions about these Terms, please contact us at <a href="mailto:info@betfolio.co">info@betfolio.co.</a></p>
                    </div>
                </Container>
            </section>
        </>
    );
};
export default TermServices;