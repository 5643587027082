import { Dropdown, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Web3 from "web3";
import { adConfig, gasFeePercent, mintNftsList, NftBuyContractAddress, tokenAddress } from "../config/config";
import { tokenAbi } from "../config/tokenAbi";
import { ethers } from "ethers";
import swal from "sweetalert";
import NftBuyAbi from "../common/NftBuyAbi.json";
import { useEffect, useState } from "react";
import { adClient } from "./adConfig";
import AdComponent from "./AdComponent";

const NftMint = ({ prediction, showNftMint, walletAddress, mintNft, showFollowSteps, tokenApprovedForNFT, setShowFollowSteps }) => {

    /* detail page follow step add of persona */

    //   useEffect(() => {
    //     adClient.showBannerAd({
    //         adUnitId: "2e4a94fa-c9c8-47a4-891a-8e99306a5653", // Live
    //     // adUnitId: "24fc3e1c-ce25-456c-8d84-4fe1dc8646f3", // Test
    //         containerId: "ads-follow-step"
    //       }, (errorMessage) => {
    //         console.log("errorMessage", errorMessage);
    //         // You can handle error here.
    //       })
    //   }, []);
    const adData = adConfig["PredictionDetail"]; // Get specific page ad config


    return (
        <>
            {!walletAddress?.accounts && walletAddress && showNftMint ? <div className="mint-btn">
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Mint NFT
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {mintNftsList.map(item => {
                            return (<Dropdown.Item onClick={() => mintNft(item)}>{item.mintName}</Dropdown.Item>)
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div> : ""}

            <Modal
                show={showFollowSteps}
                backdrop="static"
                onHide={() => setShowFollowSteps(false)}
                centered
                className="connect-wallet-box follow-steps-popup"
            >
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="mt-0">Follow Steps </h3>
                    {window.innerWidth <= 768 ? (
      <AdComponent adId={adData.ids[2]} dimension={adData.dimensions[2]} className="follow-steps-ad-mid" />
    ) : (
      <AdComponent adId={adData.ids[3]} dimension={adData.dimensions[3]} className="follow-steps-ad-mid" />
    )}
                    {/* <AdComponent adId={adData.ids[2]} dimension={adData.dimensions[2]} className={"follow-steps-ad-mid"} /> */}
                    <div className="step-area">
                        <div className="steps-left-area">
                            {tokenApprovedForNFT ? <h2>✓</h2> : <div class="loader"></div>}
                        </div>
                        <div className="steps-content">
                            <h6>Approving USDC</h6>
                            <p>Approving USDC</p>
                        </div>
                    </div>
                    <div className="step-area">
                        <div className="steps-left-area">
                            {tokenApprovedForNFT ? <div class="loader"></div> : <h2>2</h2>}
                        </div>
                        <div className="steps-content">
                            <h6>Mint NFT</h6>
                            <p>Send transaction to Mint NFT</p>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default NftMint;
