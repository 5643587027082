import React, { useState } from "react";
import Web3 from "web3";
import { Container, Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const TransferFunds = () => {
  const [privateKey, setPrivateKey] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [network, setNetwork] = useState("amoy");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    if (!Web3.utils.isAddress(receiverAddress)) {
      setErrorMessage("Invalid receiver address.");
      return;
    }

    try {
      const rpcUrls = {
        amoy: "https://polygon-amoy-bor-rpc.publicnode.com",
        mainnet: "https://polygon-bor-rpc.publicnode.com",
      };

      const web3 = new Web3(rpcUrls[network]);

      const account = web3.eth.accounts.privateKeyToAccount(privateKey);
      web3.eth.accounts.wallet.add(account);
      web3.eth.defaultAccount = account.address;

      const gasPrice = await web3.eth.getGasPrice();
      const adjustedGasPrice = web3.utils.toWei(
        (2 * parseFloat(web3.utils.fromWei(gasPrice, "gwei"))).toString(),
        "gwei"
      );
      const maxPriorityFeePerGas = web3.utils.toWei("30", "gwei");

      const nonce = await web3.eth.getTransactionCount(account.address, "latest");

      const gasLimit = await web3.eth.estimateGas({
        from: account.address,
        to: receiverAddress,
        value: web3.utils.toWei(amount, "ether"),
      });

      const tx = {
        from: account.address,
        to: receiverAddress,
        value: web3.utils.toWei(amount, "ether"),
        gas: gasLimit,
        maxPriorityFeePerGas: maxPriorityFeePerGas,
        maxFeePerGas: adjustedGasPrice,
        nonce: nonce,
      };

      const signedTx = await web3.eth.accounts.signTransaction(tx, privateKey);

      const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);

      setSuccessMessage(`Transaction successful! TX Hash: ${receipt.transactionHash}`);
    } catch (error) {
      console.error("Error sending transaction:", error);
      setErrorMessage("Failed to send transaction. Please try again.");
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Body>
              <h2 className="card-title text-center mb-4">Send Transaction</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Network:</Form.Label>
                  {/* <Form.Control
                    as="select"
                    value={network}
                    onChange={(e) => setNetwork(e.target.value)}
                    required
                  > */}
                    <Form.Select aria-label="Select"
                    as="select"
                    value={network}
                    onChange={(e) => setNetwork(e.target.value)}
                    required>
                    <option value="amoy">Polygon Amoy (Testnet)</option>
                    <option value="mainnet">Polygon Mainnet</option>
                    </Form.Select>
                    
                  {/* </Form.Control> */}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Private Key:</Form.Label>
                  <Form.Control
                    type="password"
                    value={privateKey}
                    onChange={(e) => setPrivateKey(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Receiver Address:</Form.Label>
                  <Form.Control
                    type="text"
                    value={receiverAddress}
                    onChange={(e) => setReceiverAddress(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Amount (MATIC):</Form.Label>
                  <Form.Control
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button type="submit" variant="primary" className="w-100">
                  Send Transaction
                </Button>
              </Form>
              {successMessage && (
                <Alert variant="success" className="mt-3">
                  {successMessage}
                </Alert>
              )}
              {errorMessage && (
                <Alert variant="danger" className="mt-3">
                  {errorMessage}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

};
export default TransferFunds;