import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
    return (
        <>
            <section className="common-padding">
                <Container>
                    <div className="terms-services-area">
                        <h2>Privacy Policy for BetFolio</h2>
                        <p>Effective Date: 02/13/2024</p>
                        <p>Welcome to BetFolio ("we", "us", "our"). We respect your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services (collectively, "Services").</p>
                        <h6>1. Information We Collect</h6>
                        <p><b>We may collect and process the following types of information:
                        </b></p>
                        <p>a. Personal Information: This includes information that can be used to identify you, such as your name, email address, and contact details.
                        </p>
                        <p>b. Financial Information: Details about your transactions and financial data related to your use of our Services.
                        </p>
                        <p>c. Usage Information: Information about how you use our website and Services, including your IP address, browser type, and device information.
                        </p>
                        <h6>2. How We Use Your Information</h6>
                        <p><b>We use the information we collect for various purposes, including to:
                        </b></p>
                        <p>a. Provide, operate, and maintain our Services.
                        </p>
                        <p>b. Improve, personalize, and expand our Services.
                        </p>
                        <p>c. Understand and analyze how you use our Services.
                        </p>
                        <p>d. Develop new products, services, features, and functionality.
                        </p>
                        <p>e. Communicate with you, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes.</p>
                        <p>f. Find and prevent fraud.
                        </p>
                        <h6>3. How We Share Your Information
                        </h6>
                        <p><b>We may share your information with the following parties:

                        </b></p>
                        <p>a. <b>Service Providers:</b> We may share your information with third-party service providers that perform services for us or on our behalf, such as payment processing, data analysis, email delivery, hosting services, and customer service.
                        </p>
                        <p>b. <b>Affiliates:</b> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy.</p>
                        <p>c. <b>Legal Obligations:</b> We may disclose your information where required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).
                        </p>
                        <h6>4. Your Privacy Rights
                        </h6>
                        <p><b>You have certain rights concerning your personal information, including:
                        </b></p>
                        <p>a. The right to access, correct, or request deletion of your personal data.

                        </p>
                        <p>b. The right to object to processing, request restriction of processing, or request portability of your personal data
                        </p>
                        <p>c. If you are a resident of the European Economic Area (EEA), you have the right to complain to a data protection authority about our collection and use of your personal data.
                        </p>
                        <h6>5. Data Security
                        </h6>
                        <p>
                            We implement appropriate technical and organizational security measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. However, no security system is impenetrable, and we cannot guarantee the absolute security of your information.

                        </p>
                        <h6>6. International Transfers
</h6>
                        <p>Your information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
</p>
                        <h6>7. Changes to This Privacy Policy
</h6>
                        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of this Privacy Policy.</p>
                        <h6>8. Contact Us
</h6>
                        <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:info@betfolio.co">info@betfolio.co</a></p>
                        
                    </div>
                </Container>
            </section>
        </>
    );
};
export default PrivacyPolicy;