import { Button, Container } from "react-bootstrap";

const ComingSoon = (props) => {
    return (
        <>
            <section className="coming-soon">
                <Container>
                    <div className="coming-soon-content"> 
                        <h2>We're coming soon</h2>
                        <p>We are working hard to finish the developemt of this site. </p>
                        {/* <Button type="button" variant="unset">Connect Wallet</Button> */}
                  </div>
                </Container>
            </section>
        </>
    );
};
export default ComingSoon